import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ManageSolution from "./customerDetail/ManageSolution";
import DetectAccount from "./customerDetail/DetectAccount";
import toast from "react-hot-toast";

export default function CustomerDetail() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "3") {
      toast.dismiss();
      toast.error("준비중입니다.");
    } else {
      toast.dismiss();
      setValue(newValue);
    }
  };
  return (
    <div className="container">
      <div className="section-title">
        <h4 className="title1">
          <span className="highlight-blue">고객 목록 상세</span>
        </h4>
      </div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab label="솔루션 관리" value="1" sx={{ fontSize: "18px" }} />
          <Tab label="검출 계정" value="2" sx={{ fontSize: "18px" }} />
          <Tab label="포렌식 서비스" value="3" sx={{ fontSize: "18px" }} />
        </Tabs>
      </Box>
      <div>
        {value === "1" ? (
          <ManageSolution />
        ) : value === "2" ? (
          <DetectAccount />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
