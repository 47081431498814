import React, { useEffect, useState } from "react";
import "pages/style/form.scss";
import "pages/style/main.scss";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import apiUrl from "utils/apis";
import toast from "react-hot-toast";
import { getTokenWithHeader } from "utils/cookie/index";
import LoadingDots from "components/common/LoadingDots";
import Checkbox from "components/common/Checkbox";
import Button from "components/common/Button";

interface InquiryProps {
  menuItem: any;
}
const BASIC_URI = process.env.REACT_APP_BASIC_URI;
export default function Inquiry({ menuItem }: InquiryProps) {
  const [isSending, setSending] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      position: "",
      phone: "",
      companyName: "",
      email: "",
      content: "",
      termsAgree: false,
      category: menuItem[0].value,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required("필수 항목입니다."),
      position: Yup.string().max(255).required("필수 항목입니다."),
      phone: Yup.string()
        .matches(
          /^(010|011|016|017|018|019)-\d{3,4}-\d{4}$/,
          "유효한 전화번호를 입력하세요. (예: 010-1234-5678)"
        )
        .required("전화번호를 입력하세요."),
      companyName: Yup.string().max(255).required("필수 항목입니다."),
      email: Yup.string()
        .email("유효한 이메일 형식으로 입력해주세요.")
        .required("필수 항목입니다."),
      content: Yup.string().required("내용을 입력해주세요."),
      termsAgree: Yup.boolean().isTrue("개인정보처리방침에 동의해주세요."),
    }),
    onSubmit: (values) => {
      setSending(true);
      const config = getTokenWithHeader({ contentType: "application/json" });
      axios
        .post(`${BASIC_URI + apiUrl.inquiry}`, values, config)
        .then((result) => {
          if (result.status === 201) {
            setSending(false);
            toast.dismiss();
            alert(
              "제출이 정상적으로 완료되었습니다.\n담당자가 검토 후 빠른 시일 내에 회신 드릴 예정입니다."
            );
            window.location.reload();
          } else {
            setSending(false);
            toast.dismiss();
            toast.error("에러입니다. 관리자에게 문의하세요.");
          }
        })
        .catch((_error) => {
          setSending(false);
          toast.dismiss();
          toast.error("에러입니다. 관리자에게 문의하세요.");
        });
    },
  });
  return (
    <div className="inner-inquiry">
      <h2 className="page-title">문의하기</h2>
      <h3 className="page-subTitle">
        담당자가 검토 후 빠른 시일 내에 회신 드릴 예정입니다.
      </h3>
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div className="form-section">
          <h4 className="form-section-title">
            담당자 정보
            <p className="form-mandatory">
              <i className="asterisk">*</i>는 필수 입력 항목입니다.
            </p>
          </h4>
          <ul className="form-list">
            <li className="form-list-item">
              <label htmlFor="name" className="form-list-item-label">
                이름<i className="asterisk">*</i>
              </label>
              <input
                type="text"
                id="name"
                placeholder="담당자 이름을 입력해주세요."
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name && (
                <p className="invalid">{formik.errors.name}</p>
              )}
            </li>
            <li className="form-list-item">
              <label htmlFor="position" className="form-list-item-label">
                직급<i className="asterisk">*</i>
              </label>
              <input
                type="text"
                id="position"
                placeholder="직급을 입력해주세요."
                value={formik.values.position}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.position && formik.errors.position && (
                <p className="invalid">{formik.errors.position}</p>
              )}
            </li>
            <li className="form-list-item">
              <label htmlFor="phone" className="form-list-item-label">
                연락처<i className="asterisk">*</i>
              </label>
              <input
                type="text"
                id="phone"
                placeholder="연락처를 입력해주세요."
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone && formik.errors.phone && (
                <p className="invalid">{formik.errors.phone}</p>
              )}
            </li>
            <li className="form-list-item">
              <label htmlFor="companyName" className="form-list-item-label">
                회사(기관)명<i className="asterisk">*</i>
              </label>
              <input
                type="text"
                id="companyName"
                placeholder="회사 또는 단체명을 입력해주세요."
                value={formik.values.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.companyName && formik.errors.companyName && (
                <p className="invalid">{formik.errors.companyName}</p>
              )}
            </li>

            <li className="form-list-item">
              <label htmlFor="email" className="form-list-item-label">
                이메일<i className="asterisk">*</i>
              </label>
              <input
                type="text"
                id="email"
                placeholder="회신 받을 이메일을 입력해주세요."
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email && (
                <p className="invalid">{formik.errors.email}</p>
              )}
            </li>
          </ul>
        </div>
        <div className="form-section">
          <h4 className="form-section-title">문의 내용</h4>
          <ul className="form-list">
            <li className="form-list-item">
              <label className="form-list-item-label">
                문의 유형<i className="asterisk">*</i>
              </label>
              <Select
                value={formik.values.category}
                id="category"
                name="category"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {menuItem?.map((item: { value: string; name: string }) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </li>
            <li className="form-list-item">
              <label
                htmlFor="content"
                className="form-list-item-label align-top"
              >
                내용<i className="asterisk">*</i>
              </label>
              <textarea
                id="content"
                placeholder="내용을 입력해주세요."
                value={formik.values.content}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              {formik.touched.content && formik.errors.content && (
                <p className="invalid">{formik.errors.content}</p>
              )}
            </li>
            <li className="form-list-item">
              <div className="wrapper">
                <div className="form-list-item-terms">
                  <p className="form-list-item-terms-text">
                    (주)스냅태그(이하 '회사'라고 합니다)는 개인정보보호법 등
                    관련 법령상의 개인정보보호 규정을 준수하며 귀하의
                    개인정보보호에 최선을 다하고 있습니다. 회사는
                    개인정보보호법에 근거하여 다음과 같은 내용으로 개인정보를
                    수집 및 처리하고자 합니다. 다음의 내용을 자세히 읽어보시고
                    모든 내용을 이해하신 후에 동의 여부를 결정해주시기 바랍니다.
                    <br />
                    <br />
                    제1조(개인정보 수집 및 이용 목적)
                    <br />
                    이용자가 제공한 모든 정보는 다음의 목적을 위해 활용하며,
                    목적 이외의 용도로는 사용되지 않습니다.
                    <br />
                    - 본인확인 및 서비스 제공
                    <br />
                    <br />
                    제2조(개인정보 수집 및 이용 항목)
                    <br />
                    회사는 개인정보 수집 목적을 위하여 다음과 같은 정보를
                    수집합니다.
                    <br />
                    - 성명, 전화번호, 이메일 및 회사명, 직급, 부서 등 직장과
                    직장에 관한 정보
                    <br />
                    <br />
                    제3조(개인정보 보유 및 이용 기간)
                    <br />
                    1. 수집한 개인정보는 수집·이용 동의일로부터 개인정보
                    수집·이용 목적을 달성할 때까지 보관 및 이용합니다.
                    <br />
                    2. 개인정보 보유기간의 경과, 처리목적의 달성 등 개인정보가
                    불필요하게 되었을 때에는 지체없이 해당 개인정보를
                    파기합니다.
                    <br />
                    <br />
                    제4조(동의 거부 관리)
                    <br />
                    귀하는 본 안내에 따른 개인정보 수집·이용에 대하여 동의를
                    거부할 권리가 있습니다. 다만, 귀하가 개인정보 동의를
                    거부하시는 경우에 서비스 이용 일부 혹은 전부 제한의 불이익이
                    발생할 수 있음을 알려드립니다.
                    <br />
                    <br />
                    제5조(개인정보의 제3자 제공)
                    <br />
                    회사는 개인정보보호법에 근거하여 다음과 같은 내용으로
                    개인정보를 제3자에게 제공하고자 합니다.
                    <br />
                    1. 개인정보를 제공 받는 제3자 : 회사의 협력사 혹은 기타
                    회사의 서비스 이용을 위해 필요한 제 3자
                    <br />
                    2. 개인정보 제공 목적 : 본인확인, 서비스 제공
                    <br />
                    3. 개인정보 제공 항목 : 성명, 전화번호, 이메일 및 회사명,
                    직급, 부서 등 직장과 직장에 관한 정보
                    <br />
                    4. 개인정보 보유 및 이용기간 : 개인정보 제공 목적 달성 시
                    까지
                    <br />
                    5. 개인정보 제공 거부 시 불이익 : 서비스 이용 일부 혹은 전부
                    제한
                    <br />
                    <br />본 방침은 2024년 05월 16일부터 시행됩니다.
                  </p>
                </div>
              </div>
            </li>
            <li className="form-list-item">
              <div className="wrapper">
                <div className="form-list-item-checkbox">
                  <Checkbox
                    id="termsAgree"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="termsAgree" className="checkbox-label">
                    개인정보처리방침 동의{" "}
                    <span className="highlight">[필수]</span>
                  </label>
                </div>
              </div>
              {formik.touched.termsAgree && formik.errors.termsAgree && (
                <p className="invalid">{formik.errors.termsAgree}</p>
              )}
            </li>
          </ul>
        </div>
        <div className="form-submit-btn">
          <Button type="submit">문의하기</Button>
        </div>
      </form>
      {isSending && <LoadingDots />}
    </div>
  );
}
