import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

interface RadioProps {
  data: any[];
  value: string;
  onChange: any;
}

export default function CustomRadio({ data, value, onChange }: RadioProps) {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={onChange}
        value={value}
        defaultValue={data[0].value}
      >
        {data.map((item) => (
          <FormControlLabel
            value={item.value}
            control={<Radio />}
            label={item.label}
            key={item.value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
