import Pagination from "react-js-pagination";
import styled from "styled-components";
import {
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleDoubleRight,
  FaAngleRight,
} from "react-icons/fa";

interface PaginateProps {
  page: string | number | null;
  itemsCountPerPage: string | number;
  totalItemsCount: string | number;
  handlePageChange: (pageNumber: number) => void;
}

export default function Paginate({
  page,
  itemsCountPerPage,
  totalItemsCount,
  handlePageChange,
}: PaginateProps) {
  return (
    <PaginationContainer>
      <Pagination
        activePage={Number(page)}
        itemsCountPerPage={Number(itemsCountPerPage)}
        totalItemsCount={Number(totalItemsCount)}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        prevPageText={<FaAngleLeft />}
        nextPageText={<FaAngleRight />}
        firstPageText={<FaAngleDoubleLeft />}
        lastPageText={<FaAngleDoubleRight />}
      />
    </PaginationContainer>
  );
}

const PaginationContainer = styled.div`
  width: 100%;
  padding: 108px 0 48px;
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 10px;
      width: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        font-size: 1.2rem;
        font-weight: 700;
        color: #ccc;
        align-items: center;
        display: flex;
        justify-content: center;
      }
      &.disabled {
        a {
          color: #777;
        }
      }
      &.active {
        a {
          color: #fff;
        }
      }
    }
  }
`;
