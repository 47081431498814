import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import CreateRoutes from "router";
import { useSelector } from "react-redux";
import { RootReducerType } from "modules";
import { Toaster } from "react-hot-toast";
import { ContextModal } from "components/common/Modal";
import TopBtn from "components/common/TopBtn";
import Header from "components/layout/header/Header";
import Footer from "components/layout/footer/Footer";

function App() {
  const { open } = useSelector((state: RootReducerType) => state.modal);

  return (
    <Router>
      <Header />
      <CreateRoutes />
      {open && <ContextModal />}
      <TopBtn />
      <Toaster
        toastOptions={{
          style: {
            border: "none",
            background: "#3F424A",
            color: "#fff",
          },
        }}
      />
      <Footer />
    </Router>
  );
}

export default App;
