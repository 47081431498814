import React, { useEffect, useState } from "react";
import "pages/partner/industry/style/common.scss";
import { useFormik, getIn } from "formik";
import * as Yup from "yup";
import Button from "components/common/Button";
import axios from "axios";
import apiUrl from "utils/apis";
import { getTokenWithHeader } from "utils/cookie/index";
import { useLocation } from "react-router-dom";
import LoadingDots from "components/common/LoadingDots";
import toast from "react-hot-toast";

const baseURL = process.env.REACT_APP_BASIC_URI;

export default function DetectAccount() {
  const location = useLocation();
  const customerId = location.state.customerId || 0;
  const customerIdNumber = parseInt(customerId || "0");
  const [isSending, setSending] = useState<boolean>(false);
  useEffect(() => {
    getData();
  }, [isSending]);
  const getData = () => {
    const config = getTokenWithHeader({ contentType: "application/json" });
    axios
      .get(`${baseURL + apiUrl.getPartnerTracerUser}/${customerId}`, config)
      .then((_result) => {
        if (_result.data.users.length > 0) {
          formik.setValues({
            tracers: _result.data.users,
          });
        }
      })
      .catch((_error) => {
        alert("일시적인 통신장애로 종료 후 다시 시도해 주세요.");
      });
  };

  const formik = useFormik({
    initialValues: {
      tracers: [
        {
          email: "",
          password: "",
          id: null,
        },
      ],
    },
    validationSchema: Yup.object({
      tracers: Yup.array().of(
        Yup.object({
          email: Yup.string()
            .min(4, "올바른 형식을 입력해 주세요.")
            .max(16, "올바른 형식을 입력해 주세요.")
            .matches(/^[^\u3131-\uD79D]+$/, "한글은 입력할 수 없습니다.") // 한글 입력 방지 정규식
            .required("내용을 입력해주세요."),
          password: Yup.string()
            .min(4, "올바른 형식을 입력해 주세요.")
            .max(16, "올바른 형식을 입력해 주세요.")
            .matches(/[0-9]/, "올바른 형식을 입력해 주세요.")
            .required("내용을 입력해주세요."),
        })
      ),
    }),
    onSubmit: (values) => {
      const data = {
        labGuardProjectId: customerIdNumber,
        tracers: values.tracers,
      };
      const config = getTokenWithHeader({ contentType: "application/json" });
      setSending(true);
      axios
        .patch(`${baseURL + apiUrl.partnerTracerUserUpdate}`, data, config)
        .then((_result) => {
          alert("성공적으로 등록되었습니다.");
          setSending(false);
          getData();
        })
        .catch((_error) => {
          if (_error.response.status === 400) {
            alert("이미 등록되어 있는 계정 정보입니다.");
            setSending(false);
          } else {
            alert("일시적인 통신장애로 종료 후 다시 시도해 주세요.");
            setSending(false);
          }
        });
    },
  });

  const removeAccount = (index: number, key: number | null) => {
    if (key) {
      if (formik.values.tracers.length > 1) {
        const config = getTokenWithHeader({ contentType: "application/json" });
        setSending(true);
        axios
          .delete(`${baseURL + apiUrl.partnerTracerUserDelete}/${key}`, config)
          .then((_result) => {
            setSending(false);
            toast.dismiss();
            toast.success("삭제되었습니다.");
          })
          .catch((_error) => {
            alert("일시적인 통신장애로 종료 후 다시 시도해 주세요.");
            setSending(false);
          });
      }
    } else {
      if (formik.values.tracers.length > 1) {
        const newAccounts = [...formik.values.tracers];
        newAccounts.splice(index, 1);
        formik.setFieldValue("tracers", newAccounts);
      }
    }
  };

  const addAccount = () => {
    formik.setFieldValue("tracers", [
      ...formik.values.tracers,
      { email: "", password: "", id: null },
    ]);
  };

  return (
    <form
      className="form"
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <div>
        {formik.values.tracers.map((tracers, index) => (
          <div className="account-list" key={index}>
            <div className="form-list-item">
              <label
                className="account-list-item-label"
                htmlFor={`tracers.${index}.email`}
              >
                id
              </label>
              <input
                name={`tracers.${index}.email`}
                value={tracers.email}
                placeholder="4~16자리를 입력해주세요."
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <p className="invalid invalid-padding130">
                {getIn(formik.errors, `tracers.${index}.email`) &&
                  getIn(formik.touched, `tracers.${index}.email`) && (
                    <>{getIn(formik.errors, `tracers.${index}.email`)}</>
                  )}
              </p>
            </div>
            <div className="form-list-item">
              <label
                className="form-list-item-label"
                htmlFor={`tracers.${index}.password`}
              >
                password
              </label>
              <input
                name={`tracers.${index}.password`}
                id={`tracers.${index}.password`}
                value={tracers.password}
                placeholder="숫자 4~16자리를 입력해주세요."
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <p className="invalid">
                {getIn(formik.errors, `tracers.${index}.password`) &&
                  getIn(formik.touched, `tracers.${index}.password`) && (
                    <>{getIn(formik.errors, `tracers.${index}.password`)}</>
                  )}
              </p>
            </div>
            <button
              type="button"
              className="remove-btn"
              onClick={() => removeAccount(index, tracers.id)}
              disabled={index === 0}
            >
              X
            </button>
          </div>
        ))}
        <div className="add-btn-container">
          <Button
            type="button"
            size="lg"
            color="grey"
            onClick={addAccount}
            disabled={formik.values.tracers.length >= 40 && true}
          >
            추가 +
          </Button>
        </div>
      </div>
      <div className="form-submit-btn">
        <Button type="submit">저장</Button>
      </div>
      {isSending && <LoadingDots />}
    </form>
  );
}
