import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "pages/style/main.scss";
import SideMenu from "components/layout/sidemenu/SideMenu";

export default function PartnerIndustry() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === "/") {
      navigate("/");
    }
  }, [navigate, pathname]);

  const sideMenu = [
    {
      label: "솔루션",
      include: "solution",
      children: [
        {
          label: "솔루션 적용",
          url: "/partner-industry/apply-solution",
        },
        {
          label: "SDK 다운로드",
          url: "/partner-industry/down-solution?page=1",
          include: "solution-detail",
        },
      ],
    },
    {
      label: "고객",
      include: "customer",
      children: [
        {
          label: "고객 목록",
          url: "/partner-industry/customer-list?page=1",
          include: "customer-detail",
        },
        {
          label: "고객 추가",
          url: "/partner-industry/add-customer",
        },
      ],
    },
    {
      label: "지원",
      children: [
        {
          label: "SDK 문의",
          url: "/partner-industry/inquiry-sdk",
        },
        {
          label: "협력사 검출계정",
          url: "/partner-industry/partner-account?page=1",
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <main className="has-margin">
        <div className="bg-container no-padding">
          <Outlet />
        </div>
      </main>
      <SideMenu sideMenu={sideMenu} />
    </React.Fragment>
  );
}
