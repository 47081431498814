import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style/sidemenu.scss";
import { FaCaretDown } from "react-icons/fa";
import toast from "react-hot-toast";

interface SideMenuProps {
  sideMenu: any[];
}

export default function SideMenu({ sideMenu }: SideMenuProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isSubMenuActive = (childUrl: string) => {
    const baseUrl = childUrl.split("?")[0];
    return pathname === baseUrl;
  };
  const isDetailMenuActive = (url: string, include: string | string[]) => {
    if (include) {
      const segments = pathname.split("/");
      const lastSegment = segments.pop();

      if (Array.isArray(include)) {
        return include.some((inc) => lastSegment?.includes(inc));
      } else {
        return lastSegment?.includes(include);
      }
    }
  };

  return (
    <nav className="side-menu-container">
      <div className="slide-menu-list">
        {sideMenu.map((item) => (
          <Accordion defaultExpanded={true} key={item.label}>
            <AccordionSummary
              className={
                (item.children &&
                  item.children.some((child: any) =>
                    isSubMenuActive(child.url)
                  )) ||
                (pathname.includes(item.include) &&
                  !pathname.includes("inquiry")) ||
                (item.children &&
                  item.children.some((child: any) =>
                    isDetailMenuActive(child.url, child.include)
                  ))
                  ? "active"
                  : ""
              }
              expandIcon={
                // <img src="/images/inactive_arrow_bottom.svg" alt="arrow" />
                <FaCaretDown />
              }
            >
              <Typography>{item.label}</Typography>
            </AccordionSummary>
            {item.children &&
              item.children.map((child: any) => (
                <AccordionDetails key={child.label}>
                  <div className="sub-menu-item">
                    <span
                      className={`sub-menu${
                        isSubMenuActive(child.url) ||
                        isDetailMenuActive(child.url, child.include)
                          ? " active"
                          : ""
                      }`}
                      onClick={() =>
                        child.url === ""
                          ? (toast.dismiss(), toast.error("준비중입니다."))
                          : navigate(child.url)
                      }
                    >
                      {child.label}
                    </span>
                    {child.children && (
                      <ul className="second-sub-menu-list">
                        {child.children.map((secondChild: any) => (
                          <li
                            className={`second-sub-menu-item${
                              isSubMenuActive(secondChild.url) ? " active" : ""
                            }`}
                            onClick={() =>
                              secondChild.url === ""
                                ? (toast.dismiss(),
                                  toast.error("준비중입니다."))
                                : navigate(secondChild.url)
                            }
                            key={secondChild.label}
                          >
                            <span>{secondChild.label}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </AccordionDetails>
              ))}
          </Accordion>
        ))}
      </div>
    </nav>
  );
}
