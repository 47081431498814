import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, ToggleButtonGroup, ToggleButton } from "@mui/material";
import "./style/login.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerType } from "modules";
import loginAction from "modules/Login";
import modalAction from "modules/Modal";
import { useCookies } from "react-cookie";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "components/common/Button";
import LoadingDots from "components/common/LoadingDots";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginRequest } = loginAction;
  const { showLoginFailErrorMessage, showLoginSelectFailErrorMessage } =
    modalAction;
  const { loading, error, isLoggingIn } = useSelector(
    (state: RootReducerType) => state.login
  );
  const [cookie, setCookie, removeCookie] = useCookies();
  const initialIsRemember = cookie.rememberEmail ? true : false;
  const [isShowingPassword, setShowingPassword] = useState<boolean>(false);
  const [isRememberEmail, setRememberEmail] =
    useState<boolean>(initialIsRemember);
  const [loginType, setLoginType] = React.useState("CUSTOMER");

  const formik = useFormik({
    initialValues: {
      email: cookie.rememberEmail ? cookie.rememberEmail : "",
      password: "",
      type: "CUSTOMER",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .max(255)
        .email("올바른 이메일 주소를 입력해주세요.")
        .required("이메일을 입력해주세요."),
      password: Yup.string().max(255).required("비밀번호를 입력해주세요"),
    }),
    onSubmit: (values) => {
      // cookie에 id 저장
      if (isRememberEmail) {
        setCookie("rememberEmail", formik.values.email, {
          maxAge: 60 * 60 * 24 * 3,
        });
      } else {
        removeCookie("rememberEmail");
      }

      dispatch(loginRequest(values));
    },
  });

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 404) {
        dispatch(showLoginSelectFailErrorMessage());
      } else {
        dispatch(showLoginFailErrorMessage());
      }
    }
  }, [dispatch, error, showLoginFailErrorMessage]);

  useEffect(() => {
    if (isLoggingIn) {
      navigate("/docs-introduction");
    }
  }, [isLoggingIn, navigate]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setLoginType(value);
    formik.values.type = value;
  };

  return (
    <main>
      <div className="loginContainer">
        <div className="loginBox">
          <div className="loginTitle">
            <h3>LOGIN</h3>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <ToggleButtonGroup
              className="loginType"
              color="primary"
              value={loginType}
              exclusive
              aria-label="Platform"
              onChange={handleChange}
              id="type"
            >
              <ToggleButton value="CUSTOMER">고객사</ToggleButton>
              <ToggleButton value="PARTNER">협력사</ToggleButton>
            </ToggleButtonGroup>
            <ul className="loginInputList">
              <li className="loginInputItem">
                <TextField
                  required
                  id="email"
                  type="email"
                  name="email"
                  label="이메일"
                  variant="standard"
                  defaultValue={cookie.rememberEmail}
                  key={cookie.rememberEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </li>
              {formik.touched.email && formik.errors.email && (
                <li className="error-message">
                  올바른 이메일 주소를 입력해 주세요.
                </li>
              )}
              <li className="loginInputItem">
                <TextField
                  required
                  id="password"
                  type={isShowingPassword ? "text" : "password"}
                  name="password"
                  label="비밀번호"
                  variant="standard"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {isShowingPassword ? (
                  <span
                    className="showPassword"
                    onClick={() => setShowingPassword(false)}
                  >
                    HIDE
                  </span>
                ) : (
                  <span
                    className="showPassword"
                    onClick={() => setShowingPassword(true)}
                  >
                    SHOW
                  </span>
                )}
              </li>
              {formik.touched.password && formik.errors.password && (
                <li className="error-message">비밀번호를 입력해주세요.</li>
              )}
            </ul>
            <div className="rememberEmailContainer">
              <span
                className={`rememberEmail ${isRememberEmail ? "active" : ""}`}
                onClick={() => setRememberEmail(!isRememberEmail)}
              >
                <img src="/images/checkbox.svg" alt="checkbox" />
                <span>이메일 기억하기</span>
              </span>
            </div>
            <Button type="submit" fullWidth>
              로그인
            </Button>
          </form>
        </div>
      </div>
      {loading && <LoadingDots />}
    </main>
  );
}
