import React, { useEffect, useState } from "react";
import "pages/partner/industry/style/common.scss";
import { useSearchParams } from "react-router-dom";
import Table from "components/common/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paginate from "components/common/Paginate";
import LoadingDots from "components/common/LoadingDots";
import { getTokenWithHeader } from "utils/cookie/index";
import axios from "axios";
import apiUrl from "utils/apis";
import toast from "react-hot-toast";

const BASIC_URI = process.env.REACT_APP_BASIC_URI;

export default function PartnerAccount() {
  const [searchParams, setSearchParams] = useSearchParams();
  let page = searchParams.get("page");
  const [isSending, setSending] = useState<boolean>(false);
  const [listTotal, setListTotal] = useState<number>(0);
  const [listData, setListData] = useState<any>();

  const getList = () => {
    setSending(true);
    const config = getTokenWithHeader({ contentType: "application/json" });
    axios
      .get(
        `${BASIC_URI + apiUrl.partnerTracerAdminManage}?page=${page}`,
        config
      )
      .then((result) => {
        setSending(false);
        setListData(result.data.users);
        if (result.data.total === 0) {
          setListTotal(1);
        } else {
          setListTotal(result.data.total);
        }
      })
      .catch((_error) => {
        setSending(false);
        toast.dismiss();
        toast.error("에러입니다. 관리자에게 문의하세요.");
      });
  };

  useEffect(() => {
    getList();
  }, [page]);

  const tableHead = [{ label: "No" }, { label: "ID" }, { label: "PASSWORD" }];

  const handlePageChange = (pageNumber: number) => {
    setSearchParams({
      page: String(pageNumber),
    });
  };

  return (
    <div className="container">
      <div className="section-title">
        <h4 className="title1">
          <span className="highlight-blue">협력사 검출계정</span>
        </h4>
      </div>
      <div className="section-table">
        <Table heads={tableHead}>
          <TableBody>
            {listData && listData.length > 0 ? (
              listData.map((row: any, index: number) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {(parseInt(page || "1") - 1) * 10 + (index + 1)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.id}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.password}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  colSpan={tableHead.length}
                  component="th"
                  scope="row"
                  align="center"
                >
                  데이터가 존재하지 않습니다.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Paginate
          page={page}
          totalItemsCount={listTotal}
          handlePageChange={handlePageChange}
          itemsCountPerPage={10}
        />
      </div>
      {isSending && <LoadingDots />}
    </div>
  );
}
