import React, { useEffect, useState } from "react";
import "pages/partner/industry/style/common.scss";
import axios from "axios";
import apiUrl from "utils/apis";
import toast from "react-hot-toast";
import { getTokenWithHeader } from "utils/cookie/index";
import {
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
  FormControl,
} from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BASIC_URI = process.env.REACT_APP_BASIC_URI;
export default function ApplySolution() {
  const [solutionSelect, setSolutionSelect] = useState<string>("");
  const [versionSelect, setVersionSelect] = useState<string>("");
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [solutionOption, setSolutionOption] = useState<any>();
  const [versionOption, setVersionOption] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  useEffect(() => {
    const config = getTokenWithHeader({ contentType: "application/json" });
    axios
      .get(`${BASIC_URI + apiUrl.solutionInfo}`, config)
      .then((result) => {
        setSolutionOption(result.data.solutions);
      })
      .catch((_error) => {
        toast.dismiss();
        toast.error("에러입니다. 관리자에게 문의하세요.");
      });
  }, []);

  const handleSoultion = (e: any) => {
    setSolutionSelect(e.target.value);
    const config = getTokenWithHeader({ contentType: "application/json" });
    axios
      .get(
        `${BASIC_URI + apiUrl.solutionVersion}?solutionId=${e.target.value}`,
        config
      )
      .then((result) => {
        setVersionOption(result.data.solutions);
      })
      .catch((_error) => {
        toast.dismiss();
        toast.error("에러입니다. 관리자에게 문의하세요.");
      });
  };

  const handleVersion = (e: any) => {
    setVersionSelect(e.target.value);
    const item = versionOption.find((obj: any) => obj.id === e.target.value);
    setPdfUrl(item.file);
  };

  return (
    <div className="container">
      <div className="section-title">
        <h4 className="title1">
          <span className="highlight-blue">솔루션별 적용 방법</span>
        </h4>
      </div>
      <div className="section-selectBox">
        <FormControl fullWidth>
          <InputLabel id="select-first-label">solution</InputLabel>
          <Select
            labelId="select-first-label"
            value={solutionSelect}
            onChange={
              (event: SelectChangeEvent) => handleSoultion(event)
              // setSolutionSelect(event.target.value as string)
            }
          >
            {solutionOption?.map((item: any) => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="select-second-label">version</InputLabel>
          <Select
            labelId="select-second-label"
            value={versionSelect}
            onChange={(event: SelectChangeEvent) => handleVersion(event)}
          >
            {versionOption?.map((item: any) => (
              <MenuItem value={item.id} key={item.id}>
                {item.version}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="pdf-container">
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          noData="솔루션과 버전을 선택해주세요."
        >
          <Page pageNumber={pageNumber} />
        </Document>
        {numPages !== 0 && (
          <div className="pdfBtnContainer">
            <button
              onClick={() =>
                pageNumber > 1 ? setPageNumber(pageNumber - 1) : null
              }
            >
              <FaChevronLeft />
            </button>
            <span>
              {pageNumber} / {numPages}
            </span>
            <button
              onClick={() =>
                pageNumber < numPages ? setPageNumber(pageNumber + 1) : null
              }
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
