import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import apiUrl from "utils/apis";
import { getTokenWithHeader } from "utils/cookie/index";
import Button from "components/common/Button";

const baseURL = process.env.REACT_APP_BASIC_URI;

export default function SolutionDetail() {
  const location = useLocation();
  const solutionId = location.state.solutionId || 0;
  const navigate = useNavigate();
  const placeholderSolutionData = {
    solutionName: "",
    sortation: "",
    version: "",
    deployAt: "",
    kind: "",
    file: "",
    note: "",
    content: "",
  };

  const [soultionData, setSoultionData] = useState<any>(
    placeholderSolutionData
  );

  useEffect(() => {
    const config = getTokenWithHeader({ contentType: "application/json" });
    axios
      .get(`${baseURL + apiUrl.solutionDetail}/${solutionId}`, config)
      .then((_result) => {
        setSoultionData(_result.data);
      })
      .catch((_error) => {
        alert("일시적인 통신장애로 종료 후 다시 시도해 주세요.");
      });
  }, []);

  return (
    <div className="container">
      <div className="section-title">
        <h4 className="title1">
          <span className="highlight-blue">SDK 상세</span>
        </h4>
      </div>
      <div className="form-section">
        <ul className="form-list">
          <li className="form-list-item gap20">
            <label htmlFor="name" className="form-list-item-label">
              솔루션명
            </label>
            <input type="text" value={soultionData?.solutionName} disabled />
            <label htmlFor="position" className="form-list-item-label">
              구분
            </label>
            <input type="text" value={soultionData?.sortation} disabled />
          </li>
          <li className="form-list-item gap20">
            <label htmlFor="position" className="form-list-item-label">
              버전
            </label>
            <input type="text" value={soultionData?.version} disabled />
            <label htmlFor="position" className="form-list-item-label">
              배포일자
            </label>
            <input type="text" value={soultionData?.deployAt} disabled />
          </li>
          <li className="form-list-item gap20">
            <label htmlFor="phone" className="form-list-item-label">
              종류
            </label>
            <input type="text" value={soultionData?.kind} disabled />
            <label htmlFor="position" className="form-list-item-label">
              파일
            </label>
            <input
              type="text"
              value={soultionData?.file?.substring(
                soultionData?.file?.lastIndexOf("/") + 1
              )}
              disabled
            />
          </li>
          <li className="form-list-item gap20">
            <label htmlFor="companyName" className="form-list-item-label">
              비고
            </label>
            <input
              type="text"
              value={soultionData?.note ? soultionData.note : "-"}
              disabled
            />
          </li>

          <li className="form-list-item gap20">
            <label htmlFor="content" className="form-list-item-label align-top">
              내용
            </label>
            <textarea
              id="content"
              value={soultionData?.content}
              disabled
            ></textarea>
          </li>
        </ul>
        <div className="form-submit-btn">
          <Button type="button" color="grey" onClick={() => navigate(-1)}>
            목록
          </Button>
        </div>
      </div>
    </div>
  );
}
