import { createTheme } from "@mui/material";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#3D6AFF",
      light: "#7091FF",
    },
    mode: "dark",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          fontFamily: "'AppleSDGothic', sans-serif",
          fontWeight: "400",
          fontSize: "16px",
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "70px",
          backgroundColor: "#F2F2F3",
          borderRadius: "8px",
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          padding: "0 20px",
          lineHeight: "24px",
          letterSpacing: "-0.4px",
          color: "#999",
        },
        shrink: {
          paddingTop: "14px",
          fontSize: "14px",
          lineHeight: "16px",
          letterSpacing: "-0.28px",
          color: "#999",
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          margin: "0px",
          "&::before": {
            borderBottom: 0
          }
        },
        input: {
          padding: "15px",
          lineHeight: "24px",
          color: "#303538",
          backgroundColor: "transparent",
          border: "2px solid transparent",
          "&:focus": {
            border: "2px solid transparent",
          },
          "&:disabled": {
            backgroundColor: "transparent",
            border: "2px solid transparent",
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.12)",
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "12px 16px",
        }
      }
    },
  }
});

export default muiTheme;