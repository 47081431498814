import React, { useState } from "react";
import "pages/partner/industry/style/common.scss";
import Checkbox from "components/common/Checkbox";
import { useFormik } from "formik";
import { MenuItem, Select } from "@mui/material";
import * as Yup from "yup";
import Button from "components/common/Button";
import { getTokenWithHeader } from "utils/cookie/index";
import LoadingDots from "components/common/LoadingDots";
import axios from "axios";
import apiUrl from "utils/apis";
import toast from "react-hot-toast";

const BASIC_URI = process.env.REACT_APP_BASIC_URI;

export default function InquiryService() {
  const [isSending, setSending] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      content: "",
      termsAgree: false,
      category: "APPLICATION",
    },
    validationSchema: Yup.object({
      content: Yup.string().required("내용을 입력해주세요."),
      termsAgree: Yup.boolean().isTrue("개인정보처리방침에 동의해주세요."),
    }),
    onSubmit: (values) => {
      setSending(true);
      const config = getTokenWithHeader({ contentType: "application/json" });
      axios
        .post(`${BASIC_URI + apiUrl.inquiryService}`, values, config)
        .then((result) => {
          if (result.status === 201) {
            setSending(false);
            toast.dismiss();
            alert(
              "제출이 정상적으로 완료되었습니다.\n담당자가 검토 후 빠른 시일 내에 회신 드릴 예정입니다."
            );
            window.location.reload();
          } else {
            setSending(false);
            toast.dismiss();
            toast.error("에러입니다. 관리자에게 문의하세요.");
          }
        })
        .catch((_error) => {
          setSending(false);
          toast.dismiss();
          toast.error("에러입니다. 관리자에게 문의하세요.");
        });
    },
  });
  return (
    <div className="container">
      <div className="section-title">
        <h4 className="title1">
          <span className="highlight-blue">서비스 문의</span>
        </h4>
      </div>
      <div>
        <form
          className="form"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <ul className="form-list">
            <li className="form-list-item">
              <label className="form-list-item-label">문의 유형</label>
              <Select
                value={formik.values.category}
                id="category"
                name="category"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="APPLICATION">적용</MenuItem>
                <MenuItem value="CHANGE_REQUEST">변경요청</MenuItem>
                <MenuItem value="POC">POC</MenuItem>
                <MenuItem value="FORMAL_CONTRACT">정식계약</MenuItem>
                <MenuItem value="ERROR_BUG">오류/버그 신고</MenuItem>
              </Select>
            </li>
            <li className="form-list-item">
              <label
                htmlFor="content"
                className="form-list-item-label align-top"
              >
                내용
              </label>
              <textarea
                id="content"
                placeholder="내용을 입력해주세요."
                value={formik.values.content}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              {formik.touched.content && formik.errors.content && (
                <p className="invalid">{formik.errors.content}</p>
              )}
            </li>
            <li className="form-list-item">
              <div className="wrapper">
                <div className="form-list-item-terms">
                  <p className="form-list-item-terms-text">
                    (주)스냅태그(이하 '회사'라고 합니다)는 개인정보보호법 등
                    관련 법령상의 개인정보보호 규정을 준수하며 귀하의
                    개인정보보호에 최선을 다하고 있습니다. 회사는
                    개인정보보호법에 근거하여 다음과 같은 내용으로 개인정보를
                    수집 및 처리하고자 합니다. 다음의 내용을 자세히 읽어보시고
                    모든 내용을 이해하신 후에 동의 여부를 결정해주시기 바랍니다.
                    <br />
                    <br />
                    제1조(개인정보 수집 및 이용 목적)
                    <br />
                    이용자가 제공한 모든 정보는 다음의 목적을 위해 활용하며,
                    목적 이외의 용도로는 사용되지 않습니다.
                    <br />
                    - 본인확인 및 서비스 제공
                    <br />
                    <br />
                    제2조(개인정보 수집 및 이용 항목)
                    <br />
                    회사는 개인정보 수집 목적을 위하여 다음과 같은 정보를
                    수집합니다.
                    <br />
                    - 성명, 전화번호, 이메일 및 회사명, 직급, 부서 등 직장과
                    직장에 관한 정보
                    <br />
                    <br />
                    제3조(개인정보 보유 및 이용 기간)
                    <br />
                    1. 수집한 개인정보는 수집·이용 동의일로부터 개인정보
                    수집·이용 목적을 달성할 때까지 보관 및 이용합니다.
                    <br />
                    2. 개인정보 보유기간의 경과, 처리목적의 달성 등 개인정보가
                    불필요하게 되었을 때에는 지체없이 해당 개인정보를
                    파기합니다.
                    <br />
                    <br />
                    제4조(동의 거부 관리)
                    <br />
                    귀하는 본 안내에 따른 개인정보 수집·이용에 대하여 동의를
                    거부할 권리가 있습니다. 다만, 귀하가 개인정보 동의를
                    거부하시는 경우에 서비스 이용 일부 혹은 전부 제한의 불이익이
                    발생할 수 있음을 알려드립니다.
                    <br />
                    <br />
                    제5조(개인정보의 제3자 제공)
                    <br />
                    회사는 개인정보보호법에 근거하여 다음과 같은 내용으로
                    개인정보를 제3자에게 제공하고자 합니다.
                    <br />
                    1. 개인정보를 제공 받는 제3자 : 회사의 협력사 혹은 기타
                    회사의 서비스 이용을 위해 필요한 제 3자
                    <br />
                    2. 개인정보 제공 목적 : 본인확인, 서비스 제공
                    <br />
                    3. 개인정보 제공 항목 : 성명, 전화번호, 이메일 및 회사명,
                    직급, 부서 등 직장과 직장에 관한 정보
                    <br />
                    4. 개인정보 보유 및 이용기간 : 개인정보 제공 목적 달성 시
                    까지
                    <br />
                    5. 개인정보 제공 거부 시 불이익 : 서비스 이용 일부 혹은 전부
                    제한
                    <br />
                    <br />본 방침은 2024년 05월 16일부터 시행됩니다.
                  </p>
                </div>
              </div>
            </li>
            <li className="form-list-item">
              <div className="wrapper">
                <div className="form-list-item-checkbox">
                  <Checkbox
                    id="termsAgree"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="termsAgree" className="checkbox-label">
                    개인정보처리방침 동의{" "}
                    <span className="highlight">[필수]</span>
                  </label>
                </div>
              </div>
              {formik.touched.termsAgree && formik.errors.termsAgree && (
                <p className="invalid">{formik.errors.termsAgree}</p>
              )}
            </li>
          </ul>
          <div className="form-submit-btn">
            <Button>문의하기</Button>
          </div>
        </form>
      </div>
      {isSending && <LoadingDots />}
    </div>
  );
}
