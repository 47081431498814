import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "pages/style/main.scss";
import SideMenu from "components/layout/sidemenu/SideMenu";

export default function CustomerIndustry() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === "/") {
      navigate("/");
    }
  }, [navigate, pathname]);

  const sideMenu = [
    {
      label: "솔루션",
      children: [
        {
          label: "솔루션 적용",
          url: "/customer-industry/apply-solution",
        },
        {
          label: "SDK 다운로드",
          url: "/customer-industry/down-solution?page=1",
          include: "solution-detail",
        },
      ],
    },
    {
      label: "서비스",
      include: "service",
      children: [
        {
          label: "서비스 목록",
          url: "/customer-industry/service-list?page=1",
          include: "service-detail",
        },
        {
          label: "서비스 추가",
          url: "/customer-industry/add-service",
        },
      ],
    },
    {
      label: "지원",
      children: [
        {
          label: "서비스 문의",
          url: "/customer-industry/inquiry-service",
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <main className="has-margin">
        <div className="bg-container no-padding">
          <Outlet />
        </div>
      </main>
      <SideMenu sideMenu={sideMenu} />
    </React.Fragment>
  );
}
