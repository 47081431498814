import React from "react";
import "pages/style/main.scss";
import Inquiry from "components/layout/Inquiry/Inquiry";

export default function Main() {
  const SelectBoxItem = [
    {
      name: "모바일 보안",
      value: "MOBILE_SECURITY",
    },
    {
      name: "PC 화면 보안",
      value: "SCREEN_SECURITY",
    },
    {
      name: "출력물 보안",
      value: "PRINT_SECURITY",
    },
    {
      name: "파트너쉽 문의",
      value: "PARTNERSHIP",
    },
    {
      name: "기타",
      value: "ETC",
    },
  ];

  return (
    <React.Fragment>
      <main>
        <div className="bg-container">
          <div className="bg-flex-box">
            <img src="/images/main-top.svg" alt="LABCODE" />
            <img src="/images/main-bot.svg" alt="LABCODE" />
            <Inquiry menuItem={SelectBoxItem}></Inquiry>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
