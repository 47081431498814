import React, { useState, useEffect } from "react";
import "pages/partner/industry/style/common.scss";
import { useFormik, getIn } from "formik";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import Button from "components/common/Button";
import axios from "axios";
import apiUrl from "utils/apis";
import { getTokenWithHeader } from "utils/cookie/index";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import LoadingDots from "components/common/LoadingDots";

const baseURL = process.env.REACT_APP_BASIC_URI;

export default function ManageSolution() {
  const [optionData, setOptionData] = useState<any>();
  const [managerOptionData, setManagerOptionData] = useState<any>();
  const [snaptagManageEmail, setSnaptagManageEmail] = useState("");
  const [snaptagManagePhone, setSnaptagManagePhone] = useState("");
  const [snaptagManagePosition, setSnaptagManagePosition] = useState("");
  const location = useLocation();
  const customerId = location.state.customerId || 0;
  const [isSending, setSending] = useState<boolean>(false);

  useEffect(() => {
    const config = getTokenWithHeader({ contentType: "application/json" });
    axios
      .get(`${baseURL + apiUrl.customerOption}`, config)
      .then((_result) => {
        setOptionData(_result.data.solutions);
        formik.values.solutions[0].solutionId = _result.data.solutions[0].id;
      })
      .catch((_error) => {
        alert("일시적인 통신장애로 종료 후 다시 시도해 주세요.");
      });

    axios
      .get(`${baseURL + apiUrl.managerOption}`, config)
      .then((_result) => {
        setManagerOptionData(_result.data);
        formik.setFieldValue("snaptagManagerId", _result.data[0].id);
        setSnaptagManageEmail(_result.data[0].email);
        setSnaptagManagePhone(_result.data[0].phone);
        setSnaptagManagePosition(_result.data[0].position);
      })
      .catch((_error) => {
        alert("일시적인 통신장애로 종료 후 다시 시도해 주세요.");
      });

    getData();
  }, [isSending]);

  const getData = () => {
    const config = getTokenWithHeader({ contentType: "application/json" });
    axios
      .get(`${baseURL + apiUrl.customerDetail}/${customerId}`, config)
      .then((_result) => {
        const { id, ...dataWithoutId } = _result.data;
        const data = dataWithoutId.solutions.map((solution: any) => {
          return {
            ...solution,
            startPeriod: new Date(solution.startPeriod),
            endPeriod: new Date(solution.endPeriod),
          };
        });
        formik.setValues({
          ...dataWithoutId,
          solutions: data,
        });
      })
      .catch((_error) => {
        console.log(_error);
        alert("일시적인 통신장애로 종료 후 다시 시도해 주세요.");
      });
  };

  interface Customer {
    solutionId: number;
    mainCategory: string;
    subCategory: string;
    startPeriod: Date;
    endPeriod: Date;
    numberOfUser: number;
    employeeNumberLength: number;
    ios: { name: string; id: number | null }[];
    aos: { name: string; id: number | null }[];
    id: number | null;
  }
  const formik = useFormik({
    initialValues: {
      customerName: "",
      department: "",
      en_customerName: "",
      serviceName: "",
      managerName: "",
      position: "",
      phone: "",
      email: "",
      snaptagManagerId: 0,
      solutions: [
        {
          solutionId: 0,
          mainCategory: "POC",
          subCategory: "BEFORE",
          startPeriod: new Date(Date.now() + 24 * 60 * 60 * 1000),
          endPeriod: new Date(Date.now() + 24 * 60 * 60 * 1000),
          numberOfUser: 10,
          employeeNumberLength: 1,
          id: null,
          ios: [
            {
              name: "",
              id: null,
            },
          ],
          aos: [
            {
              name: "",
              id: null,
            },
          ],
        },
      ],
    },
    validationSchema: Yup.object({
      customerName: Yup.string().required("내용을 입력해주세요."),
      department: Yup.string().required("내용을 입력해주세요."),
      en_customerName: Yup.string().required("내용을 입력해주세요."),
      managerName: Yup.string().required("내용을 입력해주세요."),
      position: Yup.string().required("내용을 입력해주세요."),
      email: Yup.string()
        .max(255)
        .email("올바른 이메일 주소를 입력해주세요.")
        .required("이메일을 입력해주세요."),
      phone: Yup.string()
        .matches(
          /^(010|011|016|017|018|019)-\d{3,4}-\d{4}$/,
          "유효한 전화번호를 입력하세요. (예: 010-1234-5678)"
        )
        .required("전화번호를 입력하세요."),
      solutions: Yup.array().of(
        Yup.object({
          employeeNumberLength: Yup.number()
            .required("내용을 입력해주세요.")
            .min(1, "1~7까지 입력 가능합니다.")
            .max(7, "1~7까지 입력 가능합니다."),
          ios: Yup.array().of(
            Yup.object({
              name: Yup.string().required("내용을 입력해주세요."),
            })
          ),
          aos: Yup.array().of(
            Yup.object({
              name: Yup.string().required("내용을 입력해주세요."),
            })
          ),
        })
      ),
    }),
    onSubmit: (values) => {
      const config = getTokenWithHeader({ contentType: "application/json" });
      setSending(true);
      axios
        .patch(
          `${baseURL + apiUrl.customerUpdate}/${customerId}`,
          values,
          config
        )
        .then((_result) => {
          alert("성공적으로 등록되었습니다.");
          setSending(false);
        })
        .catch((_error) => {
          alert("일시적인 통신장애로 종료 후 다시 시도해 주세요.");
          setSending(false);
        });
    },
  });

  useEffect(() => {
    if (managerOptionData) {
      let managerFilter = managerOptionData.filter(
        (item: any) => item.id === formik.values.snaptagManagerId
      );
      setSnaptagManagePosition(managerFilter[0].position);
      setSnaptagManageEmail(managerFilter[0].email);
      setSnaptagManagePhone(managerFilter[0].phone);
    }
  }, [formik.values, managerOptionData]);

  const removeSolutions = (index: number, key: number | null) => {
    if (key) {
      if (formik.values.solutions.length > 1) {
        const config = getTokenWithHeader({ contentType: "application/json" });
        setSending(true);
        axios
          .delete(`${baseURL + apiUrl.partnerDelete}/${key}`, config)
          .then((_result) => {
            setSending(false);
            toast.dismiss();
            toast.success("삭제되었습니다.");
          })
          .catch((_error) => {
            alert("일시적인 통신장애로 종료 후 다시 시도해 주세요.");
            setSending(false);
          });
        getData();
      }
    } else {
      if (formik.values.solutions.length > 1) {
        const newCustomer = [...formik.values.solutions];
        newCustomer.splice(index, 1);
        formik.setFieldValue("solutions", newCustomer);
      }
    }
  };

  const addSolutions = () => {
    const filteredOptionData = optionData.filter(
      (item: any) => item.id !== formik.values.solutions[0].solutionId
    );
    formik.setFieldValue("solutions", [
      ...formik.values.solutions,
      {
        solutionId: filteredOptionData[0].id,
        mainCategory: "POC",
        subCategory: "BEFORE",
        startPeriod: new Date(Date.now() + 24 * 60 * 60 * 1000),
        endPeriod: new Date(Date.now() + 24 * 60 * 60 * 1000),
        numberOfUser: 10,
        employeeNumberLength: 1,
        id: null,
        ios: [
          {
            name: "",
            id: null,
          },
        ],
        aos: [
          {
            name: "",
            id: null,
          },
        ],
      },
    ]);
  };

  const handleDateChange = (
    date: Date | null,
    fieldName: keyof Customer,
    index: number
  ) => {
    if (date !== null) {
      const newCustomers: Customer[] = formik.values.solutions.map(
        (solutions, i) => {
          if (i === index) {
            return {
              ...solutions,
              [fieldName]: date,
            };
          }
          return solutions;
        }
      );
      if (fieldName === "startPeriod") {
        newCustomers[index].endPeriod = date;
      }
      formik.setFieldValue("solutions", newCustomers);
    }
  };

  const handelOption = (e: SelectChangeEvent<number>, index: number) => {
    const isDuplicate = formik.values.solutions.some((solutions) => {
      return solutions.solutionId === e.target.value;
    });
    if (isDuplicate) {
      toast.dismiss();
      toast.error("해당 솔루션이 기존에 추가되어 있습니다.");
      return false;
    } else {
      formik.setFieldValue(e.target.name, e.target.value);

      const selectedOption = optionData.find(
        (item: any) => item.id === e.target.value
      );
      formik.setFieldValue(
        `solutions.${index}.industryCode`,
        selectedOption.industryCode
      );
      formik.setFieldValue(
        `solutions.${index}.customerCode`,
        selectedOption.customerCode[0].code
      );
    }
  };

  const handelManagerOption = (e: SelectChangeEvent<number>) => {
    formik.setFieldValue(e.target.name, e.target.value);
    let managerFilter = managerOptionData.filter(
      (item: any) => item.id === e.target.value
    );
    setSnaptagManagePosition(managerFilter[0].position);
    setSnaptagManageEmail(managerFilter[0].email);
    setSnaptagManagePhone(managerFilter[0].phone);
  };

  const addPackages = (key: string) => {
    const arrayToUpdate = key === "aos" ? "aos" : "ios";
    const newSolutions = [...formik.values.solutions];
    const currentIndex = newSolutions.length - 1;
    newSolutions[currentIndex][arrayToUpdate].push({ name: "", id: null });
    formik.setFieldValue("solutions", newSolutions);
  };

  const deletePackages = (key: string, index: number) => {
    const arrayToUpdate = key === "aos" ? "aos" : "ios";
    const newSolutions = [...formik.values.solutions];
    const currentIndex = index;
    const lastIndex = newSolutions[currentIndex][arrayToUpdate].length - 1;
    if (newSolutions[currentIndex][arrayToUpdate][lastIndex].id === null) {
      newSolutions[currentIndex][arrayToUpdate].pop();
      formik.setFieldValue("solutions", newSolutions);
    } else {
      const config = getTokenWithHeader({ contentType: "application/json" });
      setSending(true);
      axios
        .delete(
          `${baseURL + apiUrl.packageDelete}/${
            newSolutions[currentIndex][arrayToUpdate][lastIndex].id
          }?os=${arrayToUpdate.toUpperCase()}`,
          config
        )
        .then((_result) => {
          setSending(false);
          toast.dismiss();
          toast.success("삭제되었습니다.");
        })
        .catch((_error) => {
          alert("일시적인 통신장애로 종료 후 다시 시도해 주세요.");
          setSending(false);
        });
      getData();
    }
  };

  const formalContract = (
    labGuardProjectSolutionId: number | null,
    SolutionId: number
  ) => {
    const config = getTokenWithHeader({ contentType: "application/json" });
    setSending(true);
    axios
      .get(
        `${
          baseURL + apiUrl.formalContract
        }?labGuardProjectId=${customerId}&labGuardProjectSolutionId=${labGuardProjectSolutionId}&solutionId=${SolutionId}`,
        config
      )
      .then((_result) => {
        setSending(false);
        toast.dismiss();
        toast.success("실계약 신청이 완료되었습니다.");
      })
      .catch((_error) => {
        alert("일시적인 통신장애로 종료 후 다시 시도해 주세요.");
        setSending(false);
      });
  };

  return (
    <form
      className="form"
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <div className="section-add-customer">
        <div className="form-list-item add-list-item">
          <div>
            <label className="customer-list-item-label">서비스명</label>
          </div>
          <input
            type="text"
            id="serviceName"
            value={formik.values.serviceName}
            disabled
          />
        </div>
        <div className="form-list-item add-list-item">
          <div>
            <label className="customer-list-item-label">고객명</label>
          </div>
          <input type="text" value={formik.values.customerName} disabled />
        </div>
        <div className="form-list-item add-list-item">
          <div>
            <label className="customer-list-item-label">
              고객명<label className="sub-label">(영문)</label>
            </label>
          </div>
          <input
            type="text"
            id="en_customerName"
            value={formik.values.en_customerName}
            disabled
          />
        </div>
        <div className="form-list-item add-list-item">
          <div>
            <label className="customer-list-item-label">부서명</label>
          </div>
          <input type="text" value={formik.values.department} disabled />
        </div>
      </div>
      <div className="section-add-customer info-container">
        <p>고객사</p>
        <div className="section-add-customer">
          <div className="form-list-item add-list-item">
            <div>
              <label className="customer-list-item-label">담당자</label>
              {formik.touched.managerName && formik.errors.managerName && (
                <span className="invalidTop">{formik.errors.managerName}</span>
              )}
            </div>

            <input
              type="text"
              id="managerName"
              value={formik.values.managerName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="form-list-item add-list-item">
            <div>
              <label className="customer-list-item-label">직급</label>
              {formik.touched.position && formik.errors.position && (
                <span className="invalidTop">{formik.errors.position}</span>
              )}
            </div>

            <input
              type="text"
              id="position"
              value={formik.values.position}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="form-list-item add-list-item">
            <div>
              <label className="customer-list-item-label">메일</label>
              {formik.touched.email && formik.errors.email && (
                <span className="invalidTop">{formik.errors.email}</span>
              )}
            </div>

            <input
              type="text"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="form-list-item add-list-item">
            <div>
              <label className="customer-list-item-label">연락처</label>
              {formik.touched.phone && formik.errors.phone && (
                <span className="invalidTop">{formik.errors.phone}</span>
              )}
            </div>

            <input
              type="text"
              id="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
      </div>
      <div className="section-add-customer info-container">
        <p>스냅태그</p>
        <div className="section-add-customer">
          <div className="form-list-item add-list-item">
            <label className="customer-list-item-label">담당자</label>
            <Select
              value={formik.values.snaptagManagerId}
              //onChange={formik.handleChange}
              onChange={handelManagerOption}
              onBlur={formik.handleBlur}
              id="snaptagManagerId"
              name="snaptagManagerId"
              size="small"
            >
              {managerOptionData?.map((item: any) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="form-list-item add-list-item">
            <label className="customer-list-item-label">직급</label>
            <input
              type="text"
              id="snaptagManagePosition"
              value={snaptagManagePosition}
              disabled
            />
          </div>
          <div className="form-list-item add-list-item">
            <label className="customer-list-item-label">메일</label>
            <input
              type="text"
              id="snaptagManageEmail"
              value={snaptagManageEmail}
              disabled
            />
          </div>
          <div className="form-list-item add-list-item">
            <label className="customer-list-item-label">연락처</label>
            <input
              type="text"
              id="snaptagManagePhone"
              value={snaptagManagePhone}
              disabled
            />
          </div>
        </div>
      </div>

      {formik.values.solutions.map((solutions, index) => (
        <div className="customer-contianer" key={index}>
          <button
            type="button"
            className="remove-btn remove-customer"
            onClick={() => removeSolutions(index, solutions.id)}
            disabled={
              index === 0 ||
              (solutions.mainCategory !== "POC" &&
                solutions.subCategory !== "BEFORE")
            }
          >
            X
          </button>
          <div className="section-add-customer">
            <div className="form-list-item add-list-item">
              <label className="customer-list-item-label">솔루션</label>
              <Select
                value={solutions.solutionId}
                onChange={(e: SelectChangeEvent<number>) =>
                  handelOption(e, index)
                }
                onBlur={formik.handleBlur}
                name={`solutions.${index}.solutionId`}
                disabled={
                  !(
                    solutions.mainCategory === "POC" &&
                    solutions.subCategory === "BEFORE"
                  )
                }
              >
                {optionData?.map((item: any) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="form-list-item add-list-item">
              <label className="customer-list-item-label">상태</label>
              <div className="item-inner">
                <Select
                  value={solutions.mainCategory}
                  name={`solutions.${index}.mainCategory`}
                  disabled
                >
                  <MenuItem value="POC">POC</MenuItem>
                  <MenuItem value="FORMAL">실계약</MenuItem>
                </Select>
                {/* <input type="text" value={solutions.subCategory} disabled /> */}
                <Select
                  value={solutions.subCategory}
                  name={`solutions.${index}.subCategory`}
                  disabled
                >
                  <MenuItem value="BEFORE">진행 전</MenuItem>
                  <MenuItem value="GOING">진행 중</MenuItem>
                  <MenuItem value="COMPLETE">완료</MenuItem>
                  <MenuItem value="BEFORE_CONTRACT">계약 전</MenuItem>
                </Select>
              </div>
            </div>
            <div className="form-list-item add-list-item">
              <label className="customer-list-item-label">기간</label>
              <div className="item-inner">
                <DatePicker
                  selected={solutions.startPeriod}
                  dateFormat="yyyy/MM/dd"
                  minDate={new Date(Date.now() + 24 * 60 * 60 * 1000)}
                  onChange={(date) =>
                    handleDateChange(date, "startPeriod", index)
                  }
                  onBlur={formik.handleBlur}
                  selectsStart
                  disabled={
                    !(
                      solutions.mainCategory === "POC" &&
                      solutions.subCategory === "BEFORE"
                    )
                  }
                  name={`solutions.${index}.startPeriod`}
                />
                <span>~</span>
                <DatePicker
                  name={`solutions.${index}.endPeriod`}
                  dateFormat="yyyy/MM/dd"
                  onChange={(date) =>
                    handleDateChange(date, "endPeriod", index)
                  }
                  onBlur={formik.handleBlur}
                  minDate={solutions.startPeriod}
                  selectsEnd
                  startDate={solutions.startPeriod}
                  endDate={solutions.endPeriod}
                  selected={solutions.endPeriod}
                  disabled={
                    !(
                      solutions.mainCategory === "POC" &&
                      solutions.subCategory === "BEFORE"
                    )
                  }
                  maxDate={
                    solutions.mainCategory === "POC"
                      ? new Date(
                          new Date(solutions.startPeriod).getTime() +
                            1000 * 60 * 60 * 24 * 21
                        )
                      : undefined
                  }
                />
                <Button
                  color="grey"
                  size="sm"
                  type="button"
                  disabled={
                    !(
                      solutions.mainCategory === "POC" &&
                      solutions.subCategory === "COMPLETE"
                    )
                  }
                  onClick={() =>
                    formalContract(solutions.id, solutions.solutionId)
                  }
                >
                  실계약 신청
                </Button>
              </div>
            </div>
          </div>
          <div className="section-add-customer">
            <div className="form-list-item add-list-item">
              <label
                className="customer-list-item-label"
                htmlFor={`solutions.${index}.numberOfUser`}
              >
                인원<label className="sub-label">(명)</label>
              </label>
              <input
                type="text"
                name={`solutions.${index}.numberOfUser`}
                value={solutions.numberOfUser}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  !(
                    solutions.mainCategory === "FORMAL" &&
                    solutions.subCategory === "BEFORE_CONTRACT"
                  )
                }
              />
            </div>
            <div className="form-list-item add-list-item">
              <div>
                <label className="customer-list-item-label">사번 자리수</label>
                {getIn(
                  formik.touched,
                  `solutions.${index}.employeeNumberLength`
                ) &&
                  getIn(
                    formik.errors,
                    `solutions.${index}.employeeNumberLength`
                  ) && (
                    <span className="invalidTop">
                      {getIn(
                        formik.errors,
                        `solutions.${index}.employeeNumberLength`
                      )}
                    </span>
                  )}
              </div>

              <input
                type="number"
                name={`solutions.${index}.employeeNumberLength`}
                value={solutions.employeeNumberLength}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  !(
                    solutions.mainCategory === "POC" &&
                    solutions.subCategory === "BEFORE"
                  )
                }
              />
            </div>
          </div>
          <div className="section-add-customer info-container">
            <p>Package Name</p>
            <div className="section-add-customer">
              <div className="form-list-item add-list-item package-item ">
                <label className="customer-list-item-label">AOS</label>
                {solutions.aos.map((solutions, indexAos) => (
                  <>
                    <input
                      type="text"
                      className="packageInput"
                      name={`solutions.${index}.aos.${indexAos}.name`}
                      value={solutions.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      key={`aos_${index}_${indexAos}`}
                      disabled={solutions.id !== null}
                    />

                    {getIn(
                      formik.errors,
                      `solutions.${index}.aos.${indexAos}.name`
                    ) &&
                      getIn(
                        formik.touched,
                        `solutions.${index}.aos.${indexAos}.name`
                      ) && (
                        <span className="invalid invalid-center">
                          {getIn(
                            formik.errors,
                            `solutions.${index}.aos.${indexAos}.name`
                          )}
                        </span>
                      )}
                  </>
                ))}
                {/* <div className="flex-center-container flex-column">
                  <Button
                    type="button"
                    size="lg"
                    color="grey"
                    onClick={() => deletePackages("aos", index)}
                    disabled={solutions.aos.length === 1 && true}
                  >
                    삭제 -
                  </Button>
                  <Button
                    type="button"
                    size="lg"
                    color="grey"
                    onClick={() => addPackages("aos")}
                    disabled={solutions.aos.length >= 3 && true}
                  >
                    추가 +
                  </Button>
                </div> */}
              </div>
              <div className="form-list-item add-list-item package-item ">
                <label className="customer-list-item-label">IOS</label>
                {solutions.ios.map((solutions, indexIos) => (
                  <>
                    <input
                      type="text"
                      className="packageInput"
                      name={`solutions.${index}.ios.${indexIos}.name`}
                      value={solutions.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      key={`ios_${index}_${indexIos}`}
                      disabled={solutions.id !== null}
                    />

                    {getIn(
                      formik.errors,
                      `solutions.${index}.ios.${indexIos}.name`
                    ) &&
                      getIn(
                        formik.touched,
                        `solutions.${index}.ios.${indexIos}.name`
                      ) && (
                        <span className="invalid invalid-center">
                          {getIn(
                            formik.errors,
                            `solutions.${index}.ios.${indexIos}.name`
                          )}
                        </span>
                      )}
                  </>
                ))}
                {/* <div className="flex-center-container flex-column">
                  <Button
                    type="button"
                    size="lg"
                    color="grey"
                    onClick={() => deletePackages("ios", index)}
                    disabled={solutions.ios.length === 1 && true}
                  >
                    삭제 -
                  </Button>
                  <Button
                    type="button"
                    size="lg"
                    color="grey"
                    onClick={() => addPackages("ios")}
                    disabled={solutions.ios.length >= 3 && true}
                  >
                    추가 +
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="add-btn-container">
        <Button
          type="button"
          size="lg"
          color="grey"
          onClick={addSolutions}
          disabled={
            formik.values.solutions.length >= optionData?.length && true
          }
        >
          추가 +
        </Button>
      </div>
      <div className="form-submit-btn">
        <Button type="submit">저장</Button>
      </div>
      {isSending && <LoadingDots />}
    </form>
  );
}
