import React, { useState, useEffect } from "react";
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import "pages/partner/industry/style/common.scss";
import Button from "components/common/Button";
import Table from "components/common/Table";
import Radio from "components/common/Radio";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paginate from "components/common/Paginate";
import FormControl from "@mui/material/FormControl";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import axios from "axios";
import apiUrl from "utils/apis";
import toast from "react-hot-toast";
import { getTokenWithHeader } from "utils/cookie/index";
import LoadingDots from "components/common/LoadingDots";

const BASIC_URI = process.env.REACT_APP_BASIC_URI;

export default function CustomerList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [listData, setListData] = useState<any>();
  const [listTotal, setListTotal] = useState<number>(0);
  const navigate = useNavigate();
  const [isSending, setSending] = useState<boolean>(false);
  const [searchOption, setSearchOption] = useState({
    solutionType: "ALL",
    mainCategory: "ALL",
    subCategory: "ALL",
  });
  const [searchName, setSearchName] = useState({
    nameValue: "",
    nameType: "CUSTOMER",
  });

  let page = searchParams.get("page");

  const tableHead = [
    { label: "No" },
    { label: "고객번호" },
    { label: "고객명" },
    { label: "부서명" },
    { label: "솔루션명" },
    { label: "수정일" },
    { label: "상태" },
    { label: "담당자" },
    { label: "포렌식" },
  ];
  const firstRadioOption = [
    { value: "ALL", label: "전체" },
    { value: "S", label: "LAB Guard S" },
    { value: "P", label: "LAB Guard P" },
  ];
  const secondRadioOption = [
    { value: "ALL", label: "전체" },
    { value: "POC", label: "POC" },
    { value: "FORMAL", label: "실계약" },
  ];
  const thirdRadioOption = [
    { value: "ALL", label: "전체" },
    { value: "BEFORE", label: "진행 전" },
    { value: "GOING", label: "진행 중" },
    { value: "COMPLETE", label: "완료" },
  ];

  useEffect(() => {
    getList();
  }, [searchOption, page]);

  const getList = () => {
    setSending(true);
    const config = getTokenWithHeader({ contentType: "application/json" });
    axios
      .get(
        `${BASIC_URI + apiUrl.partnerList}?solutionType=${
          searchOption.solutionType
        }&mainCategory=${searchOption.mainCategory}&subCategory=${
          searchOption.subCategory
        }&nameType=${searchName.nameType}&page=${page}&nameValue=${
          searchName.nameValue
        }`,
        config
      )
      .then((result) => {
        setSending(false);
        setListData(result.data.projects);
        if (result.data.total === 0) {
          setListTotal(1);
        } else {
          setListTotal(result.data.total);
        }
      })
      .catch((_error) => {
        setSending(false);
        toast.dismiss();
        toast.error("에러입니다. 관리자에게 문의하세요.");
      });
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchParams({
      page: String(pageNumber),
    });
  };
  useEffect(() => {
    if (listTotal !== 0) {
      if (listTotal / 10 + 1 < parseInt(page || "1")) {
        navigate({
          pathname: "/partner-industry/customer-list",
          search: createSearchParams({
            page: "1",
          }).toString(),
        });
      }
    } else {
      if (parseInt(page || "1") === 0) {
        navigate({
          pathname: "/partner-industry/customer-list",
          search: createSearchParams({
            page: "1",
          }).toString(),
        });
      }
    }
  }, [listTotal]);

  return (
    <div className="container">
      <div className="section-title">
        <h4 className="title1">
          <span className="highlight-blue">고객 목록</span>
        </h4>
      </div>
      <div className="section-search">
        <ul className="form-list center">
          <li className="form-list-item gap20">
            <label htmlFor="name" className="form-list-item-label">
              솔루션
            </label>
            <Radio
              data={firstRadioOption}
              value={searchOption.solutionType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchOption({
                  ...searchOption,
                  solutionType: e.target.value,
                });
              }}
            />
          </li>
          <li className="form-list-item gap20">
            <label htmlFor="name" className="form-list-item-label">
              계약
            </label>
            <Radio
              data={secondRadioOption}
              value={searchOption.mainCategory}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchOption({
                  ...searchOption,
                  mainCategory: e.target.value,
                });
              }}
            />
          </li>
          <li className="form-list-item gap20">
            <label htmlFor="name" className="form-list-item-label">
              상태
            </label>
            <Radio
              data={thirdRadioOption}
              value={searchOption.subCategory}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchOption({
                  ...searchOption,
                  subCategory: e.target.value,
                });
              }}
            />
          </li>
          <li className="form-list-item gap20">
            <label htmlFor="name" className="form-list-item-label">
              고객명
            </label>
            {/* <FormControl sx={{ minWidth: 120, height: 52 }}>
              <Select
                value={searchName.nameType}
                onChange={(e: SelectChangeEvent) =>
                  setSearchName({
                    ...searchName,
                    nameType: e.target.value,
                  })
                }
              >
                <MenuItem value="CUSTOMER">고객명</MenuItem>
                <MenuItem value="PROJECT">프로젝트명</MenuItem>
              </Select>
            </FormControl> */}
            <input
              type="text"
              id="nameInput"
              value={searchName.nameValue}
              onChange={(e) =>
                setSearchName({
                  ...searchName,
                  nameValue: e.target.value,
                })
              }
            />
            <button className="search-btn" onClick={getList}>
              검색
            </button>
          </li>
        </ul>
      </div>
      <div className="form-submit-btn">
        <Button
          size="sm"
          onClick={() => navigate("/partner-industry/add-customer")}
        >
          고객 추가
        </Button>
      </div>
      <div className="section-table">
        <Table heads={tableHead}>
          <TableBody>
            {listData && listData.length > 0 ? (
              listData.map((row: any, index: number) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() =>
                    navigate("/partner-industry/customer-detail", {
                      state: { customerId: row.id },
                    })
                  }
                  hover
                >
                  <TableCell component="th" scope="row" align="center">
                    {(parseInt(page || "1") - 1) * 10 + (index + 1)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.customerNumber}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.customerName}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.departmentName}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.solution?.map((key: any, index: number) => (
                      <React.Fragment key={index}>
                        {key.name}
                        {index !== key.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.updateAt}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.solution?.map((key: any, index: number) => (
                      <React.Fragment key={index}>
                        {key.mainCategory} /
                        {key.subCategory === "BEFORE"
                          ? "진행 전"
                          : key.subCategory === "GOING"
                          ? "진행 중"
                          : key.subCategory === "COMPLETE"
                          ? "완료"
                          : key.subCategory === "BEFORE_CONTRACT"
                          ? "계약 전"
                          : ""}
                        {index !== key.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.customerManager} / {row.customerPosition}
                    <br />
                    {row.snaptagManager} / {row.snaptagPosition}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.solution?.map((key: any, index: number) => (
                      <React.Fragment key={index}>
                        {/* <FaCircle color="red" /> */}-
                        {index !== key.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  colSpan={tableHead.length}
                  component="th"
                  scope="row"
                  align="center"
                >
                  데이터가 존재하지 않습니다.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Paginate
          page={page}
          totalItemsCount={listTotal}
          handlePageChange={handlePageChange}
          itemsCountPerPage={10}
        />
      </div>
      {/* {isSending && <LoadingDots />} */}
    </div>
  );
}
