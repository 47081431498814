import React from 'react';
import ReactDOM from 'react-dom/client';
import 'style/global.css';
import styledTheme from 'style/styledTheme';
import muiTheme from 'style/muiTheme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'modules';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <StyledThemeProvider theme={styledTheme}>
      <MuiThemeProvider theme={muiTheme}>
        <CookiesProvider>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </CookiesProvider>
      </MuiThemeProvider>
    </StyledThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
