const apiUrl = {
  signIn: "/v1/auth/login", // 로그인
  apiKey: "/v1/dev-center/auth/keys", // apiKey
  sdk: "/v1/dev-center/sdk", // SDK 리스트 조회
  document: "/v1/company-document", // 회사 문서 조회, 생성
  inquiry: "/v1/inquiry/create", //문의하기
  inquirySdk: "/v1/inquiry/create/sdk", //sdk문의하기
  inquiryService: "/v1/inquiry/create/service", //sdk문의하기
  partnerList: "/v1/labGuardProject/partner/list", //협력사 고객목록 리스트
  customerList: "/v1/labGuardProject/customer/list", //고객사 고객목록 리스트
  partnerOption: "/v1/solution/partner", //협력사 고객추가 옵션
  customerOption: "/v1/solution/customer", //고객사 서비스추가 옵션
  managerOption: "/v1/snaptag-manager", //협력사 고객추가 옵션
  partnerCreate: "/v1/labGuardProject/partner/create", //솔루션 추가
  partnerUpdate: "/v1/labGuardProject/partner/update", //솔루션 수정
  partnerDetail: "/v1/labGuardProject/partner/detail", //솔루션 고객 정보 가져오기
  customerDetail: "/v1/labGuardProject/customer/detail", //솔루션 고객 정보 가져오기
  customerUpdate: "/v1/labGuardProject/customer/update", //솔루션 수정
  partnerDelete: "/v1/labGuardProject/partner/delete", //솔루션 고객 정보 삭제하기(개별삭제)
  packageDelete: "/v1/labGuardProject/package/delete", //솔루션 고객 정보 삭제하기(개별삭제)
  customerCreate: "/v1/labGuardProject/customer/create", //서비스 추가
  getPartnerTracerUser: "/v1/tracer-user/partner/manage", //검출계정 조회
  getCustomerTracerUser: "/v1/tracer-user/customer/manage", //검출계정 조회
  partnerTracerUserUpdate: "/v1/tracer-user/partner/update", //검출계정 수정/추가
  customerTracerUserUpdate: "/v1/tracer-user/customer/update", //검출계정 수정/추가
  partnerTracerUserDelete: "/v1/tracer-user/partner/delete", //검출계정 삭제하기(개별삭제)
  customerTracerUserDelete: "/v1/tracer-user/customer/delete", //검출계정 삭제하기(개별삭제)
  getConfigFile: "/v1/labGuardProject/customer/configFile", //검출계정 조회
  partnerTracerAdminManage: "/v1/tracer-user/partner/admin/manage", //검출계정 삭제하기(개별삭제)
  solutionInfo: "/v1/solution/partner/info", //sdk다운로드 솔루션selectbox
  solutionInfoCustomer: "/v1/solution/customer/info", //sdk다운로드 솔루션selectbox(customer)
  solutionVersion: "/v1/solution/partner/version", //sdk다운로드 버젼selectbox
  solutionVersionCustomer: "/v1/solution/customer/version", //sdk다운로드 버젼selectbox(customer)
  solutionList: "/v1/solution/partner/sdk/list", //sdk다운로드 리스트
  solutionListCustomer: "/v1/solution/customer/sdk/list", //sdk다운로드 리스트
  solutionDetail: "/v1/solution/partner/sdk/detail", //sdk다운로드 디테일
  solutionDetailCustomer: "/v1/solution/customer/sdk/detail", //sdk다운로드 디테일
  formalContract: "/v1/labGuardProject/formal/mail", //실계약 신청
};

export default apiUrl;
