import React, { useState, useEffect } from "react";
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import "pages/partner/industry/style/common.scss";
import Button from "components/common/Button";
import Table from "components/common/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paginate from "components/common/Paginate";
import axios from "axios";
import apiUrl from "utils/apis";
import toast from "react-hot-toast";
import { getTokenWithHeader } from "utils/cookie/index";
import LoadingDots from "components/common/LoadingDots";
import { FaArrowDown } from "react-icons/fa";
import { styled } from "@mui/system";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "150px", // 원하는 최대 너비 설정
}));

const BASIC_URI = process.env.REACT_APP_BASIC_URI;
const imageURI = process.env.REACT_APP_AWS_IMAGE_URI;
const replaceImageURI = process.env.REACT_APP_REPLACE_IMAGE_URI;

export default function DownSolution() {
  const [isSending, setSending] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [listTotal, setListTotal] = useState<number>(0);
  const [listData, setListData] = useState<any>();
  const [downloadStatus, setDownloadStatus] = useState({
    isOnProcess: false,
    loaded: 0,
    total: 0,
  });
  let page = searchParams.get("page");
  const navigate = useNavigate();

  const tableHead = [
    { label: "No" },
    { label: "솔루션명" },
    { label: "구분" },
    { label: "버전" },
    { label: "배포일자" },
    { label: "종류" },
    { label: "파일" },
    { label: "비고" },
    { label: "다운로드" },
  ];
  useEffect(() => {
    getList();
  }, [page]);

  const getList = () => {
    setSending(true);
    const config = getTokenWithHeader({ contentType: "application/json" });
    axios
      .get(`${BASIC_URI + apiUrl.solutionListCustomer}?page=${page}`, config)
      .then((result) => {
        setSending(false);
        setListData(result.data.solutions);
        if (result.data.total === 0) {
          setListTotal(1);
        } else {
          setListTotal(result.data.total);
        }
      })
      .catch((_error) => {
        setSending(false);
        toast.dismiss();
        toast.error("에러입니다. 관리자에게 문의하세요.");
      });
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchParams({
      page: String(pageNumber),
    });
  };
  useEffect(() => {
    if (listTotal !== 0) {
      if (listTotal / 10 + 1 < parseInt(page || "1")) {
        navigate({
          pathname: "/customer-industry/down-solution",
          search: createSearchParams({
            page: "1",
          }).toString(),
        });
      }
    } else {
      if (parseInt(page || "1") === 0) {
        navigate({
          pathname: "/customer-industry/down-solution",
          search: createSearchParams({
            page: "1",
          }).toString(),
        });
      }
    }
  }, [listTotal]);

  const downloadFile = async (file: string, event: any) => {
    event.stopPropagation();
    const fileName = file.substring(file.lastIndexOf("/") + 1);
    setDownloadStatus({
      isOnProcess: true,
      loaded: 0,
      total: 0,
    });

    if (file === null || file === undefined) {
      setDownloadStatus((prev) => ({
        ...prev,
        isOnProcess: false,
      }));
      return;
    } else {
      try {
        file = file.replace(imageURI || "", replaceImageURI || "");
        const res = await axios.get(file, { responseType: "blob" });
        res &&
          setDownloadStatus((prev) => ({
            ...prev,
            loaded: prev.loaded + 1,
          }));
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`);
        link.click();
        setDownloadStatus((prev) => ({
          ...prev,
          isOnProcess: false,
        }));
      } catch (error) {
        setDownloadStatus((prev) => ({
          ...prev,
          isOnProcess: false,
        }));
        console.log(error);
      }
    }
  };
  return (
    <div className="container">
      <div className="section-title">
        <h4 className="title1">
          <span className="highlight-blue">SDK 다운로드</span>
        </h4>
      </div>
      <div className="form-submit-btn">
        <Button
          size="sm"
          onClick={() => navigate("/customer-industry/inquiry-service")}
        >
          서비스 문의
        </Button>
      </div>
      <div className="section-table">
        <Table heads={tableHead}>
          <TableBody>
            {listData && listData.length > 0 ? (
              listData.map((row: any, index: number) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() =>
                    navigate("/customer-industry/solution-detail", {
                      state: { solutionId: row.id },
                    })
                  }
                  hover
                >
                  <StyledTableCell component="th" scope="row" align="center">
                    {(parseInt(page || "1") - 1) * 10 + (index + 1)}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {row.solutionName} <span>({row.os})</span>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {row.sortation}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {row.version}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {row.deployAt}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {row.kind}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {row.file.substring(row.file.lastIndexOf("/") + 1)}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {row.note ? row.note : "-"}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    <button
                      className="download-btn"
                      onClick={(event) => downloadFile(row.file, event)}
                    >
                      <FaArrowDown />
                    </button>
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  colSpan={tableHead.length}
                  component="th"
                  scope="row"
                  align="center"
                >
                  데이터가 존재하지 않습니다.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Paginate
          page={page}
          totalItemsCount={listTotal}
          handlePageChange={handlePageChange}
          itemsCountPerPage={10}
        />
      </div>
      {isSending && <LoadingDots />}
    </div>
  );
}
