import React, { useEffect, useRef, useState } from "react";
import "./style/header.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import loginAction from "modules/Login";
import { RootReducerType } from "modules";
import toast from "react-hot-toast";

export default function Header() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logoutRequest } = loginAction;
  const { isLoggingIn, email } = useSelector(
    (state: RootReducerType) => state.login
  );
  const myPageNavRef = useRef<HTMLDivElement | null>(null);
  const myapplicationRef = useRef<HTMLDivElement | null>(null);

  const [isDropMenuOpen, setDropMenuOpen] = useState<boolean>(false);
  const [isAppDropMenuOpen, setAppDropMenuOpen] = useState<boolean>(false);
  const [loginType, setLoginType] = useState<string | null>("");

  useEffect(() => {
    if (!isLoggingIn) {
      navigate("/");
    }
    setLoginType(sessionStorage.getItem("type"));
  }, [isLoggingIn, navigate]);

  useEffect(() => {
    const handleClose = (e: { target: any }) => {
      if (
        isDropMenuOpen &&
        (!myPageNavRef.current || !myPageNavRef.current.contains(e.target))
      )
        setDropMenuOpen(false);
    };
    document.addEventListener("click", handleClose);
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, [isDropMenuOpen]);

  useEffect(() => {
    const handleAppClose = (e: { target: any }) => {
      if (
        isAppDropMenuOpen &&
        (!myapplicationRef.current ||
          !myapplicationRef.current.contains(e.target))
      )
        setAppDropMenuOpen(false);
    };
    document.addEventListener("click", handleAppClose);
    return () => {
      document.removeEventListener("click", handleAppClose);
    };
  }, [isAppDropMenuOpen]);

  const handleLogout = () => {
    dispatch(logoutRequest());
    navigate("/");
  };

  const moveIndustry = (info: string) => {
    if (loginType === "PARTNER") {
      navigate(`/partner-industry/${info}`);
      setDropMenuOpen(false);
    } else {
      navigate(`/customer-industry/${info}`);
      setDropMenuOpen(false);
    }
  };

  const readyPage = () => {
    toast.dismiss();
    toast.error("페이지 준비중입니다.");
  };

  return (
    <>
      <header className="header-container">
        <div className="logo-container">
          <img
            className="logo"
            src="/images/logo.svg"
            alt="logo"
            onClick={() => navigate("/")}
          />
        </div>
        {isLoggingIn ? (
          <nav className="nav-container">
            <ul className="nav-list">
              <li className="nav-list-item" onClick={readyPage}>
                <span
                  className={`nav-list-item-text${
                    pathname === "/products-main" ? " active" : ""
                  }`}
                >
                  Products
                </span>
              </li>
              {/* <li
                className="nav-list-item"
                onClick={() => navigate("/docs-industry-list")}
              >
                <span
                  className={`nav-list-item-text${
                    pathname.includes("/docs") ? " active" : ""
                  }`}
                >
                  My Application
                </span>
              </li> */}
            </ul>

            <div ref={myapplicationRef} className="my-application-nav">
              <div
                className="application-container"
                onClick={() => setAppDropMenuOpen(!isAppDropMenuOpen)}
              >
                <span className="application-head">My Application</span>
                <img
                  className={`arrow-down-icon${
                    isAppDropMenuOpen ? " open" : ""
                  }`}
                  src="/images/arrow.svg"
                  alt="arrow"
                />
              </div>
              {isAppDropMenuOpen && (
                <div className="my-application-nav-container">
                  <ul className="my-application-nav-list">
                    <li
                      className="my-application-nav-list-item"
                      onClick={() => moveIndustry("apply-solution")}
                    >
                      <span className="application-btn">기업보안</span>
                    </li>
                    <li
                      className="my-application-nav-list-item"
                      //onClick={() => moveMyInfo("sdk")}
                      onClick={readyPage}
                    >
                      <span className="application-btn">Generative AI</span>
                    </li>
                    <li
                      className="my-application-nav-list-item"
                      //onClick={() => moveMyInfo("sdk")}
                      onClick={readyPage}
                    >
                      <span className="application-btn">IP Protection</span>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div ref={myPageNavRef} className="my-page-nav">
              <div
                className="user-name-container"
                onClick={() => setDropMenuOpen(!isDropMenuOpen)}
              >
                <span className="user-name">{email}</span>
                <img
                  className={`arrow-down-icon${isDropMenuOpen ? " open" : ""}`}
                  src="/images/arrow.svg"
                  alt="arrow"
                />
              </div>
              {isDropMenuOpen && (
                <div className="my-page-nav-container">
                  <ul className="my-page-nav-list">
                    <li
                      className="my-page-nav-list-item"
                      onClick={handleLogout}
                    >
                      <span className="log-out-btn">로그아웃</span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </nav>
        ) : (
          <></>
        )}
      </header>
    </>
  );
}
