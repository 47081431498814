import React, { ChangeEventHandler, useState, useEffect } from "react";
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import "pages/partner/industry/style/common.scss";
import Button from "components/common/Button";
import Table from "components/common/Table";
import Radio from "components/common/Radio";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paginate from "components/common/Paginate";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import apiUrl from "utils/apis";
import toast from "react-hot-toast";
import { getTokenWithHeader } from "utils/cookie/index";
import LoadingDots from "components/common/LoadingDots";

const BASIC_URI = process.env.REACT_APP_BASIC_URI;

export default function ServiceList() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSending, setSending] = useState<boolean>(false);
  const [listData, setListData] = useState<any>();
  const [listTotal, setListTotal] = useState<number>(0);
  let page = searchParams.get("page");
  const tableHead = [
    { label: "No" },
    { label: "서비스명" },
    { label: "솔루션명" },
    { label: "사용인원" },
    { label: "수정일" },
    { label: "상태" },
    { label: "담당자" },
    { label: "포렌식" },
  ];

  const getList = () => {
    setSending(true);
    const config = getTokenWithHeader({ contentType: "application/json" });
    axios
      .get(`${BASIC_URI + apiUrl.customerList}?&page=${page}`, config)
      .then((result) => {
        setSending(false);
        setListData(result.data.projects);
        if (result.data.total === 0) {
          setListTotal(1);
        } else {
          setListTotal(result.data.total);
        }
      })
      .catch((_error) => {
        setSending(false);
        toast.dismiss();
        toast.error("에러입니다. 관리자에게 문의하세요.");
      });
  };

  useEffect(() => {
    getList();
  }, [page]);

  useEffect(() => {
    if (listTotal !== 0) {
      if (listTotal / 10 + 1 < parseInt(page || "1")) {
        navigate({
          pathname: "/customer-industry/service-list",
          search: createSearchParams({
            page: "1",
          }).toString(),
        });
      }
    } else {
      if (parseInt(page || "1") === 0) {
        navigate({
          pathname: "/customer-industry/service-list",
          search: createSearchParams({
            page: "1",
          }).toString(),
        });
      }
    }
  }, [listTotal]);
  const handlePageChange = (pageNumber: number) => {
    setSearchParams({
      page: String(pageNumber),
    });
  };
  return (
    <div className="container">
      <div className="section-title">
        <h4 className="title1">
          <span className="highlight-blue">서비스 목록</span>
        </h4>
      </div>
      <div className="form-submit-btn flex-continer gap20">
        <Button
          size="sm"
          onClick={() => navigate("/customer-industry/inquiry-service")}
        >
          서비스 문의
        </Button>
        <Button
          size="sm"
          onClick={() => navigate("/customer-industry/add-service")}
        >
          신규 등록
        </Button>
      </div>
      <div className="section-table">
        <Table heads={tableHead}>
          <TableBody>
            {listData && listData.length > 0 ? (
              listData.map((row: any, index: number) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() =>
                    navigate("/customer-industry/service-detail", {
                      state: { customerId: row.id },
                    })
                  }
                  hover
                >
                  <TableCell component="th" scope="row" align="center">
                    {(parseInt(page || "1") - 1) * 10 + (index + 1)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.serviceName}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.solution?.map((key: any, index: number) => (
                      <React.Fragment key={index}>
                        {key.name}
                        {index !== key.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.solution?.map((key: any, index: number) => (
                      <React.Fragment key={index}>
                        {key.numberOfUsers}
                        {index !== key.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.updateAt}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.solution?.map((key: any, index: number) => (
                      <React.Fragment key={index}>
                        {key.mainCategory} /
                        {key.subCategory === "BEFORE"
                          ? "진행 전"
                          : key.subCategory === "GOING"
                          ? "진행 중"
                          : key.subCategory === "COMPLETE"
                          ? "완료"
                          : key.subCategory === "BEFORE_CONTRACT"
                          ? "계약 전"
                          : ""}
                        {index !== key.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.customerManager} / {row.customerPosition}
                    <br />
                    {row.snaptagManager} / {row.snaptagPosition}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    -
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  colSpan={tableHead.length}
                  component="th"
                  scope="row"
                  align="center"
                >
                  데이터가 존재하지 않습니다.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Paginate
          page={page}
          totalItemsCount={listTotal}
          handlePageChange={handlePageChange}
          itemsCountPerPage={10}
        />
      </div>
      {isSending && <LoadingDots />}
    </div>
  );
}
