import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import loginAction from "modules/Login";
import { RootReducerType } from "modules";
import Main from "pages/Main";
import Login from "pages/Login";
import PartnerIndustry from "pages/PartnerIndustry";
import CustomerIndustry from "pages/CustomerIndustry";
import ApplySolutionPartner from "pages/partner/industry/ApplySolution";
import ApplySolutionCustomer from "pages/customer/industry/ApplySolution";
import DownSolutionPartner from "pages/partner/industry/DownSolution";
import SolutionDetailPartner from "pages/partner/industry/SolutionDetail";
import SolutionDetailCustomer from "pages/customer/industry/SolutionDetail";
import DownSolutionCustomer from "pages/customer/industry/DownSolution";
import CustomerList from "pages/partner/industry/CustomerList";
import CustomerDetail from "pages/partner/industry/CustomerDetail";
import ServiceDetail from "pages/customer/industry/ServiceDetail";
import ServiceList from "pages/customer/industry/ServiceList";
import InquirySdk from "pages/partner/industry/InquirySdk";
import InquiryService from "pages/customer/industry/InquiryService";
import PartnerAccount from "pages/partner/industry/PartnerAccount";
import AddCustomer from "pages/partner/industry/AddCustomer";
import AddService from "pages/customer/industry/AddService";

export default function CreateRoutes() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { loginSuccess, logoutSuccess } = loginAction;
  const { error } = useSelector((state: RootReducerType) => state.login);

  // 로그인 검증
  useEffect(() => {
    const email = sessionStorage.getItem("email");
    const accessToken = sessionStorage.getItem("accessToken");
    if (
      accessToken === undefined ||
      accessToken === null ||
      accessToken === ""
    ) {
      dispatch(logoutSuccess());
    } else {
      if (error !== null) {
        dispatch(logoutSuccess());
      }
      dispatch(loginSuccess({ email }));
    }
  }, [dispatch, error, loginSuccess, logoutSuccess]);

  // 페이지 이동 시 상단 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/main" element={<Main />} />
      <Route path="/partner-industry" element={<PartnerIndustry />}>
        <Route path="apply-solution" element={<ApplySolutionPartner />} />
        <Route path="down-solution" element={<DownSolutionPartner />} />
        <Route path="customer-list" element={<CustomerList />} />
        <Route path="partner-account" element={<PartnerAccount />} />
        <Route path="add-customer" element={<AddCustomer />} />
        <Route path="inquiry-sdk" element={<InquirySdk />} />
        <Route path="customer-detail" element={<CustomerDetail />} />
        <Route path="solution-detail" element={<SolutionDetailPartner />} />
      </Route>
      <Route path="/customer-industry" element={<CustomerIndustry />}>
        <Route path="apply-solution" element={<ApplySolutionCustomer />} />
        <Route path="down-solution" element={<DownSolutionCustomer />} />
        <Route path="solution-detail" element={<SolutionDetailCustomer />} />
        <Route path="service-detail" element={<ServiceDetail />} />
        <Route path="service-list" element={<ServiceList />} />
        <Route path="add-service" element={<AddService />} />
        <Route path="inquiry-service" element={<InquiryService />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/main" />} />
    </Routes>
  );
}
