import React, { useEffect, useState } from "react";
import "pages/partner/industry/style/common.scss";
import axios from "axios";
import apiUrl from "utils/apis";
import { getTokenWithHeader } from "utils/cookie/index";
import { useLocation } from "react-router-dom";
import LoadingDots from "components/common/LoadingDots";
import { MenuItem, Select } from "@mui/material";
import { FaArrowDown } from "react-icons/fa";

const baseURL = process.env.REACT_APP_BASIC_URI;
const imageURI = process.env.REACT_APP_AWS_IMAGE_URI;
const replaceImageURI = process.env.REACT_APP_REPLACE_IMAGE_URI;

export default function DownLoadLABCurity() {
  const location = useLocation();
  const customerId = location.state.customerId || 0;
  const [optionData, setOptionData] = useState<any>();
  const [isSending, setSending] = useState<boolean>(false);
  const [listData, setListData] = useState<any>();
  const [downloadStatus, setDownloadStatus] = useState({
    isOnProcess: false,
    loaded: 0,
    total: 0,
  });
  useEffect(() => {
    const config = getTokenWithHeader({ contentType: "application/json" });
    axios
      .get(`${baseURL + apiUrl.customerOption}`, config)
      .then((_result) => {
        setOptionData(_result.data.solutions);
      })
      .catch((_error) => {
        alert("일시적인 통신장애로 종료 후 다시 시도해 주세요.");
      });
    getData();
  }, [isSending]);
  const getData = () => {
    const config = getTokenWithHeader({ contentType: "application/json" });
    axios
      .get(`${baseURL + apiUrl.getConfigFile}/${customerId}`, config)
      .then((_result) => {
        setListData(_result.data.solutions);
      })
      .catch((_error) => {
        alert("일시적인 통신장애로 종료 후 다시 시도해 주세요.");
      });
  };
  const downloadFile = async (file: string, event: any) => {
    event.stopPropagation();
    const fileName = file.substring(file.lastIndexOf("/") + 1);
    setDownloadStatus({
      isOnProcess: true,
      loaded: 0,
      total: 0,
    });

    if (file === null || file === undefined) {
      setDownloadStatus((prev) => ({
        ...prev,
        isOnProcess: false,
      }));
      return;
    } else {
      try {
        file = file.replace(imageURI || "", replaceImageURI || "");
        const res = await axios.get(file, { responseType: "blob" });
        res &&
          setDownloadStatus((prev) => ({
            ...prev,
            loaded: prev.loaded + 1,
          }));
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`);
        link.click();
        setDownloadStatus((prev) => ({
          ...prev,
          isOnProcess: false,
        }));
      } catch (error) {
        setDownloadStatus((prev) => ({
          ...prev,
          isOnProcess: false,
        }));
        console.log(error);
      }
    }
  };

  return (
    <>
      <div>
        {listData &&
          listData.length > 0 &&
          listData.map((data: any, index: number) => (
            <div className="account-list">
              <div className="form-list-item add-list-item">
                <label className="account-list-item-label">솔루션</label>
                <div className="item-inner">
                  <Select value={data.solutionId} disabled>
                    {optionData?.map((item: any) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="form-list-item add-list-item">
                <label className="form-list-item-label">상태</label>
                <div className="item-inner">
                  <input type="text" disabled value={data.mainCategory} />
                  <input type="text" disabled value={data.subCategory} />
                </div>
              </div>
              <div className="form-list-item add-list-item">
                <label className="form-list-item-label">Config File</label>
                <div className="item-inner">
                  <input
                    type="text"
                    disabled
                    value={
                      data.configFile
                        ? data.configFile.substring(
                            data.configFile.lastIndexOf("/") + 1
                          )
                        : "-"
                    }
                  />
                  <button
                    className="download-btn"
                    onClick={(event) => downloadFile(data.configFile, event)}
                    disabled={
                      data.configFile === null || data.subCategory !== "GOING"
                    }
                  >
                    <FaArrowDown />
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
      {isSending && <LoadingDots />}
    </>
  );
}
